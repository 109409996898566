// 3rd-party Plugins
// @import "~highlight.js/styles/googlecode.css";
@import "~perfect-scrollbar/css/perfect-scrollbar.css";

// Theme Styles
@import "./assets/sass/style.react.scss";

// Default Layout themes
@import "./assets/sass/themes/layout/header/base/light.scss";
@import "./assets/sass/themes/layout/header/menu/light.scss";
@import "./assets/sass/themes/layout/brand/dark.scss";
@import "./assets/sass/themes/layout/aside/dark.scss";

// Header themes
// Light
//@import "./_metronic/_assets/sass/themes/layout/header/base/light.scss";
// Dark
// @import "./_metronic/_assets/sass/themes/layout/header/base/dark.scss";

// Header Menu themes
// Light
//@import "./_metronic/_assets/sass/themes/layout/header/menu/light.scss";
// Dark
// @import "./_metronic/_assets/sass/themes/layout/header/menu/dark.scss";

// Brand themes
.brand-dark {
  @import "./assets/sass/themes/layout/brand/dark.scss";
}
// Light
.brand-light {
  @import "./assets/sass/themes/layout/brand/light.scss";
}

// Aside themes
// Dark
//@import "./_metronic/_assets/sass/themes/layout/aside/dark.scss";
// Light
// @import "./_metronic/_assets/sass/themes/layout/aside/light.scss";

// .example-highlight {
//   padding: 0rem 1.5rem 1.75rem !important;
// }

.example-highlight > pre {
  background: none transparent !important;
  margin: 0 !important;
  padding-top: 0.5rem;
  code {
    overflow-y: auto;
    display: block;

    span {
      background: none transparent !important;
    }
  }
}

.json > pre {
  background: none #fff !important;
}

.example-code > .json {
  background: none #fff !important;
}

.symbol.symbol-45 > svg {
  width: 100%;
  max-width: 45px;
  height: 45px;
}

.react-bootstrap-table {
  overflow-x: auto;
}

.react-bootstrap-table {
  th {
    outline: none;
    white-space: nowrap;
    min-width: 100px;

    &.sortable {
      .svg-icon-sort {
        opacity: 0;
      }

      &:hover {
        cursor: pointer;

        .svg-icon-sort {
          opacity: 1;
        }
      }
    }
  }

  .table.table-head-custom thead tr {
    .sortable-active {
      color: $primary !important;
    }
  }
}

.cursor-default {
  cursor: default !important;
}

.display-block {
  display: block;
}

.top-select {
  min-width: 75px;
}

.react-bootstrap-table-pagination-list {
  display: flex;
  justify-content: flex-end;
}

.action-btn {
  background-color: #009688;
}

.action-btn svg rect {
  fill: white;
}

.navigation-icon svg,
.action-icon svg {
  width: 1.5rem;
  height: 1.5rem;
}

.icon-white path {
  fill: white;
}

.upload-image button:hover {
  background-color: transparent;
}

.ql-container {
  height: 12rem !important;
}

.ws-nowrap {
  white-space: nowrap;
}

.logo {
  width: 4rem;
  height: auto;
}

.image-add-icon {
  transform: scale(4);
  padding: 0 !important;
  margin: 1rem !important;
}

.row-hover tbody tr:hover {
  background-color: #c6c6c6;
  color: black;
  cursor: pointer;
}

.delete-image-btn {
  right: 0;
  top: 0;
  background-color: #c6c6c6 !important;
  color: red !important;
  padding: 4px !important;
  border-radius: 4px !important;

  & svg {
    font-size: 2.25rem;
  }
}

.fit-content {
  width: fit-content !important;
}

.delete-action-btn {
  color: red !important;
  padding: 4px !important;
  border-radius: 4px !important;

  &:hover {
    background-color: white !important;
  }

  & svg {
    font-size: 2.25rem;
  }
}

.edit-action-btn {
  color: gray !important;
  padding: 4px !important;
  border-radius: 2px !important;

  &:hover {
    background-color: white !important;
  }

  & svg {
    font-size: 2rem;
  }
}

select {
  -webkit-appearance: none;
  appearance: none;
}

.ck {
  height: 200px;
}
.width-5 {
  width: 8%;
}

.w-200px{
  width: 200px !important;
}